import React, { forwardRef } from "react";
import "./CheckoutProduct.css";
import { useStateValue } from "./StateProvider";

const CheckoutProduct = forwardRef(
  ({ key, id, title, image, price, rating, hideButton }, ref) => {
    const [{}, dispatch] = useStateValue();
    const removeFromBasket = () => {
      dispatch({
        type: "REMOVE_FROM_BASKET",
        id: id,
      });
    };
    return (
      <div className="checkoutProduct" ref={ref}>
        <img className="checkoutProduct__image" src={image} alt="" />
        <div className="checkoutProduct__info">
          <p className="checkoutProduct__title">{title}</p>
          <p className="checkoutProduct__price">
            <small>$</small>
            <strong>{price}</strong>
          </p>
          <p className="checkoutProduct__rating">
            {Array(rating)
              .fill()
              .map((_, i) => (
                <p>
                  <span role="img" aria-label="star">
                    &#11088;
                  </span>
                </p>
              ))}
          </p>
          {!hideButton && (
            <button
              onClick={removeFromBasket}
              className="checkoutProduct__remove"
            >
              Remove from basket
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default CheckoutProduct;
