import React from "react";
import "./Home.css";
import Product from "./Product";

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <img className="home__image" src="/images/amazon_home.jpg" alt="" />

        <div className="home__row">
          <Product
            id="00001"
            title="The lean startup"
            image="/images/leanstartup.jpg"
            price={19.99}
            rating={5}
          />
          <Product
            id="00002"
            title="Kenwood"
            image="/images/leanstartup.jpg"
            price={239.0}
            rating={4}
          />
        </div>

        <div className="home__row">
          <Product
            id="00003"
            title="Samung"
            image="/images/leanstartup.jpg"
            price={199.99}
            rating={3}
          />
          <Product
            id="00004"
            title="Alexa 3rd Gen"
            image="/images/leanstartup.jpg"
            price={98.99}
            rating={5}
          />
          <Product
            id="00005"
            title="iPad Pro"
            image="/images/leanstartup.jpg"
            price={598.99}
            rating={4}
          />
        </div>

        <div className="home__row">
          <Product
            id="00006"
            title="Samung Monitor"
            image="/images/leanstartup.jpg"
            price={1094.98}
            rating={4}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
