import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC5jBQjNrSfGOMQDfTW-eMXIJb8aGZRjlY",
  authDomain: "clone-73d54.firebaseapp.com",
  databaseURL: "https://clone-73d54.firebaseio.com",
  projectId: "clone-73d54",
  storageBucket: "clone-73d54.appspot.com",
  messagingSenderId: "803890707641",
  appId: "1:803890707641:web:2b5a821907aca5be79ef68",
  measurementId: "G-5JYXDTTHMB",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
